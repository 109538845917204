.px-6 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.about-company-item {
  margin: 20px 0 0 0;
}

.btn-orange {
  background-color: #ff0b0b !important;
  border: none !important;

}

.form-row {
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>[class*=col-] {
  padding: 0 5px;
}


.logo {
  width: 100px;
  height: 50px;
}

.customText {
  font-size: 14px !important;
}

.customFooterText {
  color: lightgray;
  text-decoration: none;
}

.forStaff:hover {
  color: white;
  text-decoration: none;
}

.officeMap {
  width: 100%;
  height: 720px;
}

.aboutCompany {
  font-size: 16px !important;
  text-align: justify;
  padding-top: 24px;
  text-indent: 40px;
}

.navbar-dark .navbar-nav a.nav-link {
  color: rgb(255 255 255 / 84%);
  font-size: 18px;
  font-weight: 600;
}

.contacts a {
  color: #ff0b0b;
  text-decoration: none;
}

.contacts a:hover {
  color: #ff4400;
}

.about-company-item p {
  padding: 24px 16px 0 16px;
}

/* sm */
@media (min-width: 768px) {
  .logo {
    width: 100px;
    height: 50px;
  }

  .officeMap {
    width: 100%;
    height: 420px;
  }

  .about-company-item p {
    padding: 24px 0 0 0;
  }
}

/* md */
@media (min-width: 992px) {
  .logo {
    width: 150px;
    height: 75px;
  }

  .officeMap {
    width: 100%;
    height: 420px;
  }

  .aboutCompany {
    font-size: 20px !important;
  }

  .customText {
    font-size: 16px !important;
  }

}